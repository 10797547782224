
  import { defineComponent } from 'vue';
import SubTituloLinha from './SubTituloLinha.vue';
  
  export default defineComponent({
    name: 'SobreEmpresa',
    props: {
        TextoPrimerioPagrafo: {
            type: String,
            default: ""
        },
        TituloPrimeiroParagrafo: {
            type: String,
            default: ""
        },
        TextoSegundoPagrafo: {
            type: String,
            default: ""
        },
        TituloSegundoParagrafo: {
            type: String,
            default: ""
        },
    },
    components: { SubTituloLinha }
});
