
import { defineComponent } from 'vue';
import MenuHorizontal from './components/MenuHorizontal.vue';
import FooterPagina from './components/FooterPagina.vue';

export default defineComponent({
  name: 'App',
  components: {
    MenuHorizontal,
    FooterPagina
  }
});
