
import { defineComponent } from 'vue';
import BotaoAcao from './BotaoAcao.vue';
  
  export default defineComponent({
    name: 'MenuHorizontal',
    props: {
      Texto: {
          type: String,
          default: ""
      },
      TextoWhatsApp: {
        type: String,
        default: ""
      }
    },
    components: { 
      BotaoAcao
    },
    methods: {
      abreWhatsApp : function() {
        window.open(`https://wa.me//5567999871739?text=${this.TextoWhatsApp.replaceAll(" ", "%20")}`)
      },
      ScrollInicio: function(){
        const input = document.getElementsByClassName('SCROLL_INICIO')[0];
        input?.scrollIntoView();
      },
      ScrollEmpresa: function(){
        const input = document.getElementsByClassName('SCROLL_EMPRESA')[0];
        input?.scrollIntoView();
        window.scrollBy(0, 280);
      },
      ScrollServico: function(){
        const input = document.getElementsByClassName('SCROLL_SERVICO')[0];
        input?.scrollIntoView();
        window.scrollBy(0, 50);
      },
      ScrollContato: function(){
        const input = document.getElementsByClassName('SCROLL_CONTATO')[0];
        input?.scrollIntoView();
        window.scrollBy(0, -20);
      }
    }
});

