import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6232cc84"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-sobre" }
const _hoisted_2 = { class: "container-sobre" }
const _hoisted_3 = { class: "w-45" }
const _hoisted_4 = { class: "paragrafo" }
const _hoisted_5 = { class: "w-45" }
const _hoisted_6 = { class: "paragrafo" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SubTituloLinha = _resolveComponent("SubTituloLinha")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_SubTituloLinha, { Texto: _ctx.TituloPrimeiroParagrafo }, null, 8, ["Texto"]),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.TextoPrimerioPagrafo), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_SubTituloLinha, {
          Texto: _ctx.TituloSegundoParagrafo,
          right: true
        }, null, 8, ["Texto"]),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.TextoSegundoPagrafo), 1)
      ])
    ])
  ]))
}