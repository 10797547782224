
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    name: 'BotaoAcao',
    props: {
      Texto: {
        type: String,
        default: ""
      },
      Onclick: {
        type: String,
        default: ""
      }
    }
  });
