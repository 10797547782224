
import { defineComponent } from 'vue';
import BotaoAcao from './BotaoAcao.vue';
  
  export default defineComponent({
    name: 'ImagemParallax',
    props: {
        Titulo: {
            type: String,
            default: ""
        },
        SubTitulo: {
            type: String,
            default: ""
        },
        TextoWhatsApp: {
            type: String,
            default: ""
        },
        Imagem: {
          type: String,
          default: "SemImagem.png"
        },
        Height: {
          type: String,
          default: ""
        }
    },
    components: { 
        BotaoAcao
    },
    methods: {
      abreWhatsApp : function() {
        window.open(`https://wa.me//5567999871739?text=${this.TextoWhatsApp.replaceAll(" ", "%20")}`)
      }
    }
});
