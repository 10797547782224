
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SubTituloPagina',
  props: {
    Texto: {
      type: String,
      default: ""
    }
  },
  
});
