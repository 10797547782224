
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'SubTituloLinha',
    props: {
        Texto: {
            type: String,
            default: ""
        },
        right: {
            type: Boolean,
            default: false
        }
    },
});
