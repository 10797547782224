import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26ec8df6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "title-sobre"
}
const _hoisted_2 = {
  key: 1,
  class: "title-sobre text-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.right)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.Texto), 1))
      : _createCommentVNode("", true),
    (_ctx.right)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.Texto), 1))
      : _createCommentVNode("", true)
  ], 64))
}