
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    name: 'CaixaCliente',
    props: {
        Imagem: {
            type: String,
            default: "logo.png"
        },
        height: {
            type: String,
            default: "120px"
        }
    }
  });
