import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c964d7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "module content" }
const _hoisted_2 = { class: "container-module" }
const _hoisted_3 = { class: "container-images SCROLL_SERVICO" }
const _hoisted_4 = { class: "main-servico" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImagemParallax = _resolveComponent("ImagemParallax")!
  const _component_SobreEmpresa = _resolveComponent("SobreEmpresa")!
  const _component_SubTituloLinha = _resolveComponent("SubTituloLinha")!
  const _component_CaixaCliente = _resolveComponent("CaixaCliente")!
  const _component_SubTituloPagina = _resolveComponent("SubTituloPagina")!
  const _component_CaixaServico = _resolveComponent("CaixaServico")!
  const _component_TextoCentralizado = _resolveComponent("TextoCentralizado")!
  const _component_ContatoIndex = _resolveComponent("ContatoIndex")!
  const _component_WhatsappFlutuante = _resolveComponent("WhatsappFlutuante")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ImagemParallax, {
      Imagem: "ImagemPrincipal.jpg",
      TextoWhatsApp: "Olá, Gostaria de fazer um orçamento",
      Titulo: "Elétrica, Automação e Energia Solar",
      SubTitulo: "Industrial, Rural, Comercial e Residencial"
    }),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_SobreEmpresa, {
          TituloPrimeiroParagrafo: "SOBRE NÓS",
          TextoPrimerioPagrafo: "Desde 2010 atuando seriamente na execução de Instalações Elétricas e Automações Industriais, a LimaTec conseguiu firmar sua marca nesse mercado com participação em mais de 500 obras por todo Brasil e conquistar uma imagem de solidez e confiança. Ao longo desses anos, nos dedicamos em expandir nossa área de atuação, trabalhando também no setor industrial, rural e fotovoltaico.",
          TituloSegundoParagrafo: "ÁREAS ATUANTES",
          TextoSegundoPagrafo: "A LimaTec se orgulha em ter desenvolvido know-how suficiente para construir usinas fotovoltaicas, sistemas de controle e automação industrial, sistemas especiais para Data Centers, telecomunicações e dados. E o mais importante: orgulho de tornar as edificações habitáveis e as indústrias prontas para operar em plena carga, realizando as instalações da melhor maneira possível, sem comprometer o andamento do trabalho e a segurança."
        }),
        _createVNode(_component_SubTituloLinha, { Texto: "ALGUNS CLIENTES" }),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_CaixaCliente, {
            Imagem: "Cliente1.jpeg",
            height: "130px"
          }),
          _createVNode(_component_CaixaCliente, {
            Imagem: "Cliente2.jpeg",
            height: "110px"
          }),
          _createVNode(_component_CaixaCliente, {
            Imagem: "Cliente3.jpeg",
            height: "110px"
          }),
          _createVNode(_component_CaixaCliente, {
            Imagem: "Cliente4.jpeg",
            height: "120px"
          })
        ]),
        _createVNode(_component_SubTituloPagina, { Texto: "Serviços" }),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_CaixaServico, {
            Imagem: "Servico1.jpg",
            Texto: "Nossa equipe faz visita no local e levantamento dos pontos importantes para um orçamento preciso.",
            Titulo: "ORÇAMENTO E PROPOSTA",
            TextoWhatsApp: "Olá gostaria de saber mais sobre Orçamento e Proposta"
          }),
          _createVNode(_component_CaixaServico, {
            Imagem: "Servico2.png",
            Texto: "Projetos elétricos, fotovoltaicos, incendio e pânico conforme as normativas de segurança.",
            Titulo: "ELABORAÇÃO DE PROJETOS",
            TextoWhatsApp: "Olá gostaria de saber mais sobre Elaboração de Projetos"
          }),
          _createVNode(_component_CaixaServico, {
            Imagem: "Servico3.jpg",
            Texto: "Instalaçoes e montagens eletricas e e automação industrial, predial, comercial e residencial.",
            Titulo: "EXECUÇÃO DE PROJETOS",
            TextoWhatsApp: "Olá gostaria de saber mais sobre Execução de projetos"
          }),
          _createVNode(_component_CaixaServico, {
            Imagem: "Servico4.jpg",
            Texto: "Desenvolvimento de sistemas de controle e automação personalizados.",
            Titulo: "AUTOMAÇÃO",
            TextoWhatsApp: "Olá gostaria de saber mais sobre Automação"
          }),
          _createVNode(_component_CaixaServico, {
            Imagem: "Servico5.jpg",
            Texto: "Deixe o sol pagar sua conta de energia! Faça um orçamento conosco.",
            Titulo: "ENERGIA SOLAR",
            TextoWhatsApp: "Olá gostaria de saber mais sobre Energia Solar"
          }),
          _createVNode(_component_CaixaServico, {
            Imagem: "Servico6.jpg",
            Texto: "Planejamento para comissionamento, start-up e operação assistida de todo o sistema.",
            Titulo: "COMISSIONAMENTO E START-UP",
            TextoWhatsApp: "Olá gostaria de saber mais sobre Comissionamento e Start-up"
          })
        ]),
        _createVNode(_component_TextoCentralizado, {
          Texto1: "",
          Texto2: "Procura alguma solução ou precisa de um orçamento?",
          Texto3: "Entre em contato conosco."
        }),
        _createVNode(_component_SubTituloPagina, { Texto: "Contatos" }),
        _createVNode(_component_ContatoIndex, {
          TelefonePrimario: "",
          TelefoneSecundario: "(67) 99987-1739",
          TextoWhatsApp: "Preciso de um eletricista!",
          Logradouro: "Rua 31 de março, 1600 - Centro",
          Cidade: "Bonito - MS",
          Cep: "79290-000",
          Pais: "Brasil",
          DiasAtendimento: "Segunda à Domingo",
          HorasAtendimento: "Atendimento 24 horas"
        })
      ])
    ]),
    _createVNode(_component_WhatsappFlutuante, { TextoWhatsApp: "Preciso de um eletricista!" })
  ], 64))
}