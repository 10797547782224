import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../assets/whatsapp.png'


const _withScopeId = n => (_pushScopeId("data-v-1dc03058"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    src: _imports_0,
    class: "button-whatsapp",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.abreWhatsApp())),
    title: _ctx.TextoWhatsApp
  }, null, 8, _hoisted_1))
}