
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    name: 'WhatsappFlutuante',
    props: {
      Texto: {
        type: String,
        default: ""
      },
      TextoWhatsApp: {
        type: String,
        default: ""
      }
    },
    methods: {
        abreWhatsApp : function() {
            window.open(`https://wa.me//5567999871739?text=${this.TextoWhatsApp.replaceAll(" ", "%20")}`)
        }
    }
  });
