import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46d629f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title-parallax" }
const _hoisted_2 = { class: "sub-title-parallax" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BotaoAcao = _resolveComponent("BotaoAcao")!

  return (_openBlock(), _createElementBlock("div", {
    class: "module-parallax SCROLL_INICIO",
    style: _normalizeStyle({ backgroundImage: 'url(' + require(`../assets/${_ctx.Imagem}`) + ')', height: _ctx.Height })
  }, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.Titulo), 1),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.SubTitulo), 1),
    (_ctx.TextoWhatsApp)
      ? (_openBlock(), _createBlock(_component_BotaoAcao, {
          key: 0,
          Texto: "FAZER UM ORÇAMENTO",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.abreWhatsApp())),
          title: _ctx.TextoWhatsApp,
          class: "SCROLL_EMPRESA"
        }, null, 8, ["title"]))
      : _createCommentVNode("", true)
  ], 4))
}