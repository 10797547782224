import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuHorizontal = _resolveComponent("MenuHorizontal")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FooterPagina = _resolveComponent("FooterPagina")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MenuHorizontal, { TextoWhatsApp: "Preciso de um eletricista!" }),
    _createVNode(_component_router_view),
    _createVNode(_component_FooterPagina, {
      LinkLinkedin: "https://www.linkedin.com/in/viniciusdeol/",
      LinkFacebook: "https://www.facebook.com/limatecbonitoms/",
      LinkInstagram: "https://www.instagram.com/limatec_bonito_ms/",
      Titulo: "LimaTec - Elétrica / Automação / Energia Solar"
    })
  ], 64))
}