
import BotaoAcao from '../../components/BotaoAcao.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ContatoIndex',
  components: {
    BotaoAcao
  },
  props: {
    TelefonePrimario: {
        type: String,
        default: ""
    },
    TelefoneSecundario: {
        type: String,
        default: ""
    },
    Logradouro: {
        type: String,
        default: ""
    },
    Cidade: {
        type: String,
        default: ""
    },
    Cep: {
        type: String,
        default: ""
    },
    Pais: {
        type: String,
        default: ""
    },
    DiasAtendimento: {
        type: String,
        default: ""
    }
    ,
    HorasAtendimento: {
        type: String,
        default: ""
    },
    TextoWhatsApp: {
        type: String,
        default: ""
    }
  },
  methods: {
    abreWhatsApp : function() {
      window.open(`https://wa.me//5567999871739?text=${this.TextoWhatsApp.replaceAll(" ", "%20")}`)
    }
  }
});
