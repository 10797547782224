
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    name: 'CaixaServico',
    props: {
        Titulo: {
            type: String,
            default: ""
        },
        Texto: {
            type: String,
            default: ""
        },
        Imagem: {
            type: String,
            default: ""
        },
        TextoWhatsApp: {
            type: String,
            default: ""
        }
    },
    methods: {
        abreWhatsApp : function(e: string) {
            window.open(`https://wa.me//5567999871739?text=${e.replaceAll(" ", "%20")}`)
        }
    }
  });
