
import { defineComponent } from 'vue';

export default defineComponent({
name: 'FooterPagina',
props: {
    Titulo: {
        type: String,
        default: ""
    },
    LinkLinkedin: {
        type: String,
        default: ""
    },
    LinkFacebook: {
        type: String,
        default: ""
    },
    LinkInstagram: {
        type: String,
        default: ""
    }
},

});
