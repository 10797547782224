
import { defineComponent } from 'vue';
import ImagemParallax from '../../components/ImagemParallax.vue';
import ContatoIndex from '../Contato/ContatoIndex.vue';
import CaixaServico from '@/components/CaixaServico.vue';
import SubTituloPagina from '@/components/SubTituloPagina.vue';
import WhatsappFlutuante from '@/components/WhatsappFlutuante.vue';
import TextoCentralizado from '@/components/TextoCentralizado.vue';
import SobreEmpresa from '@/components/SobreEmpresa.vue';
import CaixaCliente from '@/components/CaixaCliente.vue';
import SubTituloLinha from '@/components/SubTituloLinha.vue';

export default defineComponent({
  name: 'HomeIndex',
  components:{
    ImagemParallax,
    ContatoIndex,
    CaixaServico,
    SubTituloPagina,
    WhatsappFlutuante,
    TextoCentralizado,
    SobreEmpresa,
    CaixaCliente,
    SubTituloLinha
}
});

