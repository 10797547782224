
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    name: 'TextoCentralizado',
    props: {
      Texto1: {
        type: String,
        default: ""
      },
      Texto2: {
        type: String,
        default: ""
      },
      Texto3: {
        type: String,
        default: ""
      },
    }
  });
